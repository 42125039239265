import { logError } from '@qogita/logging'
import {
  getStoryblokApi,
  StoryblokComponent,
  useStoryblokState,
} from '@storyblok/react'
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'

import { PageStoryblok } from '#src/types/storyblok-component-types'

const Page = ({
  story: initialStory,
}: InferGetServerSidePropsType<
  typeof getServerSideProps
>): JSX.Element | null => {
  const story = useStoryblokState<PageStoryblok>(initialStory, {
    preventClicks: true,
  })

  return story ? (
    <StoryblokComponent blok={story.content} />
  ) : (
    <div>story is null</div>
  )
}

export const getServerSideProps = async ({
  query,
}: GetServerSidePropsContext) => {
  const isInStoryblokIframe = Boolean(query._storyblok)

  const storyblokApi = getStoryblokApi()

  try {
    const { data } = await storyblokApi.get(
      'cdn/stories/sellers-qogita-com/sellers-homepage',
      {
        version: isInStoryblokIframe ? 'draft' : 'published',
        resolve_links: 'url',
        resolve_relations: [
          'testimonialSimplePanel.testimonial',
          'page.footer',
        ],
      },
    )
    return {
      props: {
        story: data.story,
      },
    }
  } catch {
    logError(`Couldn't fetch storyblok data for home page`)

    return {
      props: {
        story: null,
      },
    }
  }
}

Page.options = {
  public: true,
}

export default Page
